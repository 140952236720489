import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import {
  IconWrapper,
  StyledErrorPanel,
  StyledInfoPanel,
  StyledSuccessPanel,
  StyledWarningPanel,
} from './styles';

export const SuccessPanel: React.FC<ComponentProps> = ({ children, ...props }) => (
  <StyledSuccessPanel {...componentProps(props)}>
    <IconWrapper>
      <Icon size="large" name="success" color={colors.notificationSuccess} />
    </IconWrapper>
    {children}
  </StyledSuccessPanel>
);

export const WarningPanel: React.FC<ComponentProps> = ({ children, ...props }) => (
  <StyledWarningPanel {...componentProps(props)}>
    <IconWrapper>
      <Icon size="large" name="warning" color={colors.notificationWarning} />
    </IconWrapper>
    {children}
  </StyledWarningPanel>
);

export const ErrorPanel: React.FC<ComponentProps> = ({ children, ...props }) => (
  <StyledErrorPanel {...componentProps(props)}>
    <IconWrapper>
      <Icon size="large" name="error" color={colors.notificationError} />
    </IconWrapper>
    {children}
  </StyledErrorPanel>
);

export const InfoPanel: React.FC<ComponentProps> = ({ children, ...props }) => (
  <StyledInfoPanel {...componentProps(props)}>
    <IconWrapper>
      <Icon size="large" name="information" color={colors.core01} />
    </IconWrapper>
    {children}
  </StyledInfoPanel>
);
