import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { gridItemPropsOverride } from '@rsa-digital/evo-shared-components/components/Grid/GridItem/styles';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';

export const PostcodeQuestionField = styled(QuestionField)`
  ${Grid} > ${GridItem} {
    ${gridItemPropsOverride({ desktop: 3, tabletLandscape: 3, tabletPortrait: 6 })}
  }
`;
