import { graphql, useStaticQuery } from 'gatsby';
import { PageTitle } from 'helpers/eventTracking';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { CsIcon, CsQuestion, CsQuestionWithPlaceholder } from 'types/contentStack';
import {
  Question,
  QuestionWithOptionalPlaceholder,
  QuestionWithPlaceholder,
} from 'types/forms';

export type AboutYouQuestions = {
  customerFirstName: QuestionWithOptionalPlaceholder;
  customerLastName: QuestionWithOptionalPlaceholder;
  customerTelephone: QuestionWithOptionalPlaceholder;
  customerEmail: QuestionWithOptionalPlaceholder;
  customerDob: Question;
  customerTitle: QuestionWithPlaceholder;
  customerGender: Question;
  numberOfPetsInHousehold: {
    question: Question;
    promotionalBox: {
      icon: [CsIcon];
      heading: string;
      information: string;
      button_text: string;
      button_screenreader_text: string;
    };
  };
};

type csAboutYouQuestions = {
  csPetAboutYouQuestions: {
    first_name: CsQuestionWithPlaceholder;
    last_name: CsQuestionWithPlaceholder;
    main_telephone: CsQuestionWithPlaceholder;
    email: CsQuestionWithPlaceholder;
    date_of_birth: CsQuestion;
    customer_title: CsQuestionWithPlaceholder;
    gender: CsQuestion;
    number_of_pets_in_household: CsQuestion & {
      promotional_box: {
        icon: [CsIcon];
        heading: string;
        information: string;
        button_text: string;
        button_screenreader_text: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetAboutYouQuestions {
      customer_title {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      first_name {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      last_name {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      gender {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      main_telephone {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      email {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      date_of_birth {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      number_of_pets_in_household {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
        promotional_box {
          icon {
            icon_code
          }
          heading
          information
          button_text
          button_screenreader_text
        }
      }
    }
  }
`;

const useAboutYouQuestions = (): AboutYouQuestions => {
  const csQuestions = useStaticQuery<csAboutYouQuestions>(query);
  const {
    processQuestion,
    processQuestionWithPlaceholder,
    processQuestionWithOptionalPlaceholder,
  } = useQuestionProcessor(PageTitle.AboutYouAndYourPet);

  return {
    customerTitle: processQuestionWithPlaceholder(
      csQuestions.csPetAboutYouQuestions.customer_title
    ),
    customerFirstName: processQuestionWithOptionalPlaceholder(
      csQuestions.csPetAboutYouQuestions.first_name
    ),
    customerLastName: processQuestionWithOptionalPlaceholder(
      csQuestions.csPetAboutYouQuestions.last_name
    ),
    customerGender: processQuestion(csQuestions.csPetAboutYouQuestions.gender),
    customerTelephone: processQuestionWithOptionalPlaceholder(
      csQuestions.csPetAboutYouQuestions.main_telephone
    ),
    customerEmail: processQuestionWithOptionalPlaceholder(
      csQuestions.csPetAboutYouQuestions.email
    ),
    customerDob: processQuestion(csQuestions.csPetAboutYouQuestions.date_of_birth),
    numberOfPetsInHousehold: {
      question: processQuestion(
        csQuestions.csPetAboutYouQuestions.number_of_pets_in_household
      ),
      promotionalBox:
        csQuestions.csPetAboutYouQuestions.number_of_pets_in_household.promotional_box,
    },
  };
};

export default useAboutYouQuestions;
